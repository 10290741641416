import { useContentDeliveryResource } from "#imports";

export default defineNuxtRouteMiddleware( async () => {
  const headlessState = useHeadlessDataState();

  const content = headlessState.value.data;

  try {
    const hasNoContent = !( ( content.properties.pinnedArticles?.length ?? 0 ) > 0 )
      && !( ( content.properties.content?.items?.length ?? 0 ) > 0 );

    // redirect if chapter has no content or is defined by the editor
    if ( hasNoContent || content.properties.forceRedirect ) {
      try {
        const {
          getChildren,
          getAncestors,
        } = useContentDeliveryResource();

        const children = await getChildren( content.id, {
          take: 1,
        } );

        const firstChild = children.data.value.items[0];

        let url = firstChild?.route.path;

        if ( !firstChild ) {
          const parent = await getAncestors( content.id, {
            take: 1,
          } );
          url = parent.data.value.route.path;
        }

        return await navigateTo( url, {
          external: !url.startsWith( "/" ), // internal if only a path given
        } );
      } catch ( e ) {
        console.error( "error performing redirect check", e );
      }
    }
  } catch ( e ) {
    console.error( "something broke", e );
  }
} );
